import React from "react";
import feedbackMobile from "../../images/feedback--mobile@1x.jpg";
import feedbackMobileWebp from "../../images/feedback--mobile@1x.webp";
import feedback from "../../images/feedback@1x.jpg";
import feedbackWebp from "../../images/feedback@1x.webp";
import toTop from "../../images/to-top.svg";
import toTopEn from "../../images/to-top-en.svg";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {t} from "i18next";
import {useTranslation} from "react-i18next";

const Feedback = () => {
  const {t, i18n} = useTranslation();

  return (
    <section className="feedback">
      <div className="feedback__image-wrapper image-wrapper">
        <picture>
          <source
            type="image/webp"
            media="(max-width: 1024px)"
            srcSet={feedbackMobileWebp}
          />
          <source media="(max-width: 1024px)" srcSet={feedbackMobile} />
          <source type="image/webp" srcSet={feedbackWebp} />
          <img src={feedback} width="1600" height="400" alt="Обратный отзыв" />
        </picture>
      </div>
      <div className="feedback__wrapper wrapper">
        <div className="feedback__section">
          <p className="feedback__lead-text big-text big-text--black">
            {t('lead.feedback.title')}
          </p>
          <Link className="feedback__link link-button" to="/contacts/#second">
            {t('lead.feedback.linkText')}
          </Link>
        </div>
        <div className="feedback__section">
          <div className="feedback__anchor-wrapper arrow-down arrow-down--black arrow-down--reverse">
            <AnchorLink className="feedback__moveto" href="#top">
              <img src={i18n.language === 'ru' ? toTop : toTopEn} width="120" height="120" alt="Наверх" />
            </AnchorLink>
          </div>
          <p className="feedback__text">{t('lead.feedback.hotline')}</p>
          <a className="feedback__phone" href="tel:88002002929">
            8 800 200-29-29
          </a>
          <a href="mailto:info@aquaart.ru" className="feedback__mail">
            info@aquaart.ru
          </a>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
