import React from "react";
import Lead from "../components/sections/lead";
import Feedback from "../components/sections/feedback";
import Footer from "../components/sections/footer";
import leadVideo from "../video/homepage.mp4";
import Stars from "../components/sections/stars";
import Employees from "../components/sections/employees";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const Reviews = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Helmet>
        <html lang='ru'/>
        <title>{t('lead.seo.reviews')}</title>
      </Helmet>
      <Lead
        subtitle={`Карьера`}
        title={t('lead.main-career.main-title')}
        leadVideo={leadVideo}
        videoClass={`lead__video-wrapper--big`}
      />
      <Stars/>
      <Employees/>
      <Feedback/>
      <Footer/>
    </>
  );
};

export default Reviews;
