import React, { useEffect } from "react";
import { Link } from "gatsby";
import {t} from "i18next";

const Footer = () => {
  useEffect(() => {
    const script = require("../../scripts/test-script.js");
  }, []);
  const d = new Date();

  return (
    <footer className="footer">
      <div className="footer__wrapper wrapper">
        <nav className="footer__nav nav-links nav-links--black">
          <Link className="nav-link" to="/about-company/">
            {t('lead.navigation.aboutCompany')}
          </Link>
          <Link className="nav-link" to="/brands/">
            {t('lead.navigation.ourBrands')}
          </Link>
          <Link className="nav-link" to="/career/">
            {t('lead.navigation.career')}
          </Link>
          <Link className="nav-link" to="/contacts/">
            {t('lead.navigation.contact')}
          </Link>
        </nav>
        <p className="footer__copyright">© {d.getFullYear()} {" "}
          {t('lead.navigation.copyright')}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
